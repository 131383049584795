import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/bike1.jpg'
import pic02 from '../images/bike2.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="overview"
          className={`${this.props.article === 'overview' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Overview</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Alentejo is one region of Portugal known by a warm weather,
            good food, good wine and mostly flat ... at least that's what
            all people think, but the reality is a bit different.
            What we want to propose you is a 7 day trip around the best
            places in Alentejo, staying on charm hotels and traditional farms.
          </p>
          <p>
            We love our country, but most of the time it is known by the most
            tourist beach area - Algarve - and not so much by the rest of the
            regions.
            Alentejo is the north region of Algarve and has not so warm weather,
            but you find there the solitude and the tradition of the "old" Portugal,
            something marvelous that you will enjoy!
          </p>
          <p>
            This project arises between friends who love cycling and love to be together
            enjoying the friendship and the natural beaty of the nature.
            We are designing and planning something different, so if you would like
            to spend a week with us, please fill in your contact.
            We make sure we sned you the latest informations about it in order for you
            to join us in this amazing journey!
          </p>
          {close}
        </article>

        <article
          id="route"
          className={`${this.props.article === 'route' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Route</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            We are still in a planning phase, but we will share with you
            all the plans, once they are ready to go!
          </p>
          <p>
            Keep coming here and fill the contact form if you want to receive
            our newsletter ... the making of is part of the journey ;-)
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            We we are ...
            some crazy guys! with some experience and tired of working in offices
            or schools and we want to do something fun!
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
